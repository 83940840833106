import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function AppCard(props: any) {
  const { Header, SubHeader, BodyText, SubBodyText, Icon, ...rest } = props;
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Card sx={
      { 
        alignItems: 'left', 
        textAlign: 'left', 
        color: '#F4B834',
        width: '240px',
        height: '280px',
        margin: '16px',
        border: '1px solid #F4B834 ',
        borderRadius: '8px',
        background: '#212239',
        cursor : 'pointer',
        boxSizing: 'border-box',
        '@media (max-width: 600px)': {
            width: '300px'
        }
        }}>
        
        <b>
        <IconButton size="large" color="inherit">
            <ContentCopyIcon />
        </IconButton>
        </b>
        <br />
      <CardHeader
        title={Header}
        subheader={SubHeader}
      />
      <CardContent>
        <Typography variant="body2">
          {BodyText}
        </Typography>
      </CardContent>
    </Card>
  );
}
