import Header from '../components/layouts/Header'
import AppMenu from '../components/layouts/AppMenu'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import floral from './images/Floral.png'
import HomeBGFull from './images/HomeBGFull.jpg'
import AppCard from '../components/controls/AppCard'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: 'black',
        color: 'white'
    }, 
    heading: {
        padding: '20px 15px',
        fontWeight: 'bold',
        fontSize: '36px'
    },
    subtext: {
        padding: '20px 15px',
        opacity: '0.8'
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignContent: 'center',
        padding: '50px 15px'
    },
    cardItem1: {
        width: '240px',
        height: '280px',
        margin: '16px',
        border: '1px solid #F4B834 ',
        borderRadius: '8px',
        background: '#212239',
        boxSizing: 'border-box',
        '@media (max-width: 600px)': {
            width: '300px'
        }
    },
    seperator: {
        width: '15%',
        backgroundColor: '#F4B834',
        height: '3px',
        marginBottom: '20px',
    },
    fullCard: {
        width: '100%',
        backgroundColor: '#F4B834',
        height: '280px',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    fullCardContent : {

    },
    fullCardMedia: {
        backgroundImage: `url(${floral})`
    },
    fullCardInverse: {
        width: '100%',
        backgroundColor: '#212239',
        height: '280px',
        display: 'flex',
        justifyContent: 'flex-start',
    },
}))

export default function Home() {
    const classes = useStyles();
    return (
        <>
        <Header />
        <AppMenu />
        <div style={{backgroundImage: `url(${HomeBGFull})`, backgroundSize: 'cover', overflow: 'hidden', }} className={classes.container} >
            
            <Typography className={classes.heading} variant="h5" component="h3">Welcome to EeziSure</Typography>
            
            <span className={classes.seperator}></span>
            
            <Typography variant="body1" component="p">We provide affordable insurances, funeral covers and more. You can also sell your services and products here. So sign up!</Typography>
            
            {/* This needs to be loop and take in the card behavours and styles and go to links */}
            <div className={classes.cardContainer}>
                <AppCard Header="Get a quote" SubHeader="" BodyText="Find out more about our tailored deals custom made for our startup customers. We everywhere within South Africa." SubBodyText="" />
                <AppCard Header="Get a quote" SubHeader="" BodyText="Find out more about our tailored deals custom made for our startup customers. We everywhere within South Africa." SubBodyText="" />
                <AppCard Header="Get a quote" SubHeader="" BodyText="Find out more about our tailored deals custom made for our startup customers. We everywhere within South Africa." SubBodyText="" />
                <AppCard Header="Get a quote" SubHeader="" BodyText="Find out more about our tailored deals custom made for our startup customers. We everywhere within South Africa." SubBodyText="" />
            </div>
            
            <div className={classes.fullCard}>
                <div className={classes.fullCardContent}></div>
                <div className={classes.fullCardMedia}></div>
            </div>
            
            <div className={classes.cardContainer}>
                <div className={classes.cardItem1}></div>
                <div className={classes.cardItem1}></div>
                <div className={classes.cardItem1}></div>
                <div className={classes.cardItem1}></div>
            </div>
            
            <div className={classes.fullCardInverse}>
                <div className={classes.fullCardContent}></div>
                <div className={classes.fullCardMedia}></div>
            </div>
            
            <div className={classes.cardContainer}>
                <div className={classes.cardItem1}></div>
                <div className={classes.cardItem1}></div>
                <div className={classes.cardItem1}></div>
                <div className={classes.cardItem1}></div>
            </div>
        </div>
        </>
    )
}
